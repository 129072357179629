<template>
    <div v-loading="loading">
        <el-radio-group v-model="wasteTId" size="mini" @change="getList">
            <el-radio-button v-for="t in wasteTypes" :key="t.wasteTId" :label="t.wasteTId">{{ t.cName }}</el-radio-button>
        </el-radio-group>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column label="委托单号" prop="wOrderId"></jy-table-column>
            <jy-table-column label="拟固废类别">
                <template v-slot="scope">{{ formatWasteType(scope.row.wasteTId) }}</template>
            </jy-table-column>
            <jy-table-column :label="`拟固废类别清运量(${unit})`" prop="weight"></jy-table-column>
            <jy-table-column :label="`清运总重量(${unit})`" prop="rWeight"></jy-table-column>
            <jy-table-column label="抛/重物">
                <template v-slot="scope">{{ scope.row.nature == "1" ? "抛物" : "重物" }}</template>
            </jy-table-column>
            <jy-table-column label="企业固废照片">
                <template v-slot="scope"><el-image fit="contain" class="avatar" :src="scope.row.picUrl" /></template>
            </jy-table-column>
            <jy-table-column label="状态">
                <template v-slot="scope">{{ formatStatus(scope.row.status) }}</template>
            </jy-table-column>
            <jy-table-column label="发起人" prop="cUserNa"></jy-table-column>
            <jy-table-column label="发起时间" prop="createT"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>
    </div>
</template>

<script>
    export default {
        methods: {
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/wasteOrder/pageQuery", {
                        wasteTId: this.wasteTId,
                        unitId: this.unitId,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        dType: "0"
                    })
                    .then(res => {
                        this.dataList = res.detail.list;
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            handleView() {
                this.$router.push({
                    path: "/admin/order"
                });
            },
            formatWasteType(val) {
                const w = this.wasteTypes.find(v => v.wasteTId === val);
                return w ? w.cName : "-";
            },
            formatStatus(val) {
                switch (val) {
                    case "0":
                        return "待派单";
                    case "2":
                        return "已派单";
                    case "3":
                        return "运输中";
                    case "5":
                        return "已完成";
                    case "1":
                        return "已拒绝";
                    case "4":
                        return "已取消";
                    default:
                        return "-";
                }
            }
        },
        mounted() {
            this.$http.post("/wasteType/listAll").then(res => {
                this.wasteTypes = res.detail;
                this.wasteTId = this.wasteTypes[0].wasteTId;

                this.getList();
            });
        },
        computed: {
            unit() {
                const res = this.wasteTypes.find(v => v.wasteTId === this.wasteTId);
                if (res && res.unit) {
                    return res.unit;
                }
                return '';
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                wasteTId: "",

                wasteTypes: [],
                operateList: [
                    {
                        name: "查看",
                        icon: "el-icon-delete",
                        fun: this.handleView,
                        isShow: () => {
                            return true;
                        }
                    }
                ]
            };
        },
        props: {
            unitId: String
        }
    };
</script>
<style scoped>
    .el-radio-group {
        margin-top: 15px;
    }
    .avatar {
        width: 100px;
        height: 100px;
    }
</style>
