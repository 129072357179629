<template>
    <transition>
        <component :is="current" @switch="switchPage"></component>
    </transition>
</template>

<script>
    import AccountDetail from "@/components/pages/admin/finance/detail.vue";
    import Detail from "./detail.vue";
    import List from "./list.vue";
    export default {
        created() {
            this.initDicts();
        },
        data() {
            return {
                current: "List",

                options: null,
                orgTypeList: [],
                industryList: []
            };
        },
        methods: {
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictorgtype").then(res => {
                    this.orgTypeList = res.detail;
                });
                this.$http.post("/dict/getDictValuesByDictId", "dictindustry").then(res => {
                    this.industryList = res.detail;
                });
            },
            switchPage(options) {
                this.options = options;
                this.current = options.target;
                // if (this.current === "List") {
                //     this.current = "Detail";
                // } else {
                //     this.current = "List";
                // }
            }
        },
        components: {
            Detail,
            List,
            AccountDetail
        }
    };
</script>
<style></style>
