<template>
    <div>
        <jy-dialog type="form" custom-class="dialog_form" :visible.sync="visible" :title="title" @close="close"
            :width="global.dialogWidth">
            <div>
                <el-form label-width="120px" :rules="rules" ref="_form" :model="form">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="卡号" prop="cardNo">
                                <el-input v-model="form.cardNo"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>

<script>
export default {
    components: {
    },

    data() {
        return {
            title: '',
            visible: false,
            form: {
                cardNo: '',
                unitId: ''
            },
            formBak: null,

            rules: {
                cardNo: [{ required: true, message: "卡号不能为空", trigger: "change" }]
            },
        }
    },

    methods: {
        init(options = {}) {
            if (!this.formBak) {
                this.formBak = JSON.parse(JSON.stringify(this.form));
            }
            this.title = options.title;
            this.form.unitId = options.unitId
            this.visible = true;
        },

        reset() {
            this.form = JSON.parse(JSON.stringify(this.formBak))
        },

        cancel() {
            this.visible = false;
        },

        add() {
            this.$http.post('/unitInfo/updateCardNo', {
                ...this.form
            })
            .then(() => {
                this.visible = false;
                this.$emit('update');
            })
        },

        confirm() {
            this.$refs._form.validate((valid) => {
                if (valid) {
                    this.add();
                }
            })
        },

        close() {
            this.reset();
            this.$refs._form.resetFields();
            this.visible = false;
        }
    }
}
</script>

<style>
</style>