<template>
    <div v-loading="loading">
        <el-radio-group v-model="wasteTId" size="mini" @change="getList">
            <el-radio-button v-for="t in wasteTypes" :key="t.wasteTId" :label="t.wasteTId">{{ t.cName }}</el-radio-button>
        </el-radio-group>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column label="工单编号" prop="wOrderId">
            </jy-table-column>
            <jy-table-column label="分拣中心">
                <template v-slot="scope">{{ formatUnit(scope.row.tUnitId) }}</template>
            </jy-table-column>
            <jy-table-column label="地磅名称" prop="">
                <template v-slot="scope">
                    {{ scope.row.weighBridge && scope.row.weighBridge.cName }}
                </template>
            </jy-table-column>
            <jy-table-column label="清运时间" prop="grossWT"></jy-table-column>
            <jy-table-column :label="`清运总重量(${unit})`" prop="grossW">
                <template v-slot="scope">{{ formatWeight(scope.row.grossW, scope.row.tareW) }}</template>
            </jy-table-column>
            <jy-table-column label="金额" prop="">
                <template v-slot="scope">{{formatPrice(scope.row)}}</template>
            </jy-table-column>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>
    </div>
</template>

<script>
    export default {
        methods: {
            formatUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatWeight(m, p) {
                console.log(m)
                if (m==0 && p==0) {
                    return 0;
                } else {
                    return m - (p||0);
                }
            },
            formatPrice(row){
                let tUnit = this.unitList.find(v => v.unitId === row.tUnitId);
                let weigh = this.formatWeight(row.grossW, row.tareW)
                console.log(tUnit);
                return (tUnit.price * weigh).toFixed(2)
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/wasteOrder/pageQuery", {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        wasteTId: this.wasteTId,
                        unitId: this.unitId,
                        dType: "1"
                    })
                    .then(res => {
                        this.dataList = res.detail.list;
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        mounted() {
            const p1 = this.$http.post("/unitInfo/queryUnitList", {
                cName: "",
                unitType: "3"
            });
            const p2 = this.$http.post("/wasteType/listAll");

            Promise.all([p1, p2]).then(res => {
                const [r1, r2] = res;
                this.unitList = r1.detail;

                this.wasteTypes = r2.detail;
                this.wasteTId = this.wasteTypes[0].wasteTId;
                this.getList();
            });
        },
        computed: {
            unit() {
                const res = this.wasteTypes.find(v => v.wasteTId === this.wasteTId);
                if (res && res.unit) {
                    return res.unit;
                }
                return '';
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                unitList: [],

                wasteTId: "",
                wasteTypes: []
            };
        },
        props: {
            unitId: String
        }
    };
</script>
<style scoped>
    .el-radio-group {
        margin-top: 15px;
    }
</style>
