<template>
    <div>
        <jy-dialog type="form" custom-class="dialog_form" :visible.sync="visible" :title="title" @close="close"
            :width="global.dialogWidth">
            <div>
                <el-form label-width="120px" :rules="rules" ref="_form" :model="form">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="企业名称" prop="unitNa">
                                <el-input :value="unitNa" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属机构" prop="orgNa">
                                <el-input :value="orgNa" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="账户余额" prop="remain">
                                <el-input  :value="balance" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="交易渠道" prop="chl">
                                <el-select v-model="form.chl">
                                    <el-option value="0" label="企业对公转账"></el-option>
                                    <el-option value="1" label="现金"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="充值金额(元)" prop="amount">
                                <el-input v-model="form.amount"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="备注" prop="remark">
                                <el-input v-model="form.remark"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>

<script>
export default {
    components: {
    },

    data() {
        return {
            title: '',
            visible: false,

            unitId: '',
            unitNa: '',
            orgNa: '',
            balance: null,
            form: {
                amount: null,
                chl: '0',
                remark: '',
                accountId: ''
            },
            formBak: null,

            rules: {
                cardNo: [{ required: true, message: "卡号不能为空", trigger: "change" }],
                amount: [
                    {
                        validator: (rule, value, callback) => {
                            if (String(value) === '0') {
                                callback(new Error('请输入大于零的数'));
                            } else if (!value) {
                                callback(new Error('不能为空'));
                            } else if (!/^[0-9]+(\.[0-9]+)?$/.test(value)) {
                                callback(new Error('格式错误'));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    }
                ]
            },
        }
    },

    methods: {
        init(options = {}) {
            if (!this.formBak) {
                this.formBak = JSON.parse(JSON.stringify(this.form));
            }
            this.title = options.title;
            this.unitId = options.unitId;
            this.unitNa = options.unitNa;
            this.orgNa = options.orgNa;
            this.getDetail();
            this.visible = true;
        },

        handleChange(val) {
            console.log(val)
        },

        getDetail() {
            this.$http.post('/unitInfo/account/detail', this.unitId)
                .then(res => {
                    const { detail: { accountInfo,accountEx } } = res;
                    this.balance = accountInfo.balance;
                    this.form.accountId = accountEx.accountId;
                })
        },

        reset() {
            this.form = JSON.parse(JSON.stringify(this.formBak))
        },

        cancel() {
            this.visible = false;
        },

        add() {
            if (this.form.amount) {
                this.form.amount = Number(this.form.amount);
            }
            this.$http.post('/unitInfo/account/charge', {
                ...this.form,
            })
            .then(() => {
                this.visible = false;
                this.$emit('update');
            })
        },

        confirm() {
            this.$refs._form.validate((valid) => {
                if (valid) {
                    this.add();
                }
            })
        },

        close() {
            this.reset();
            this.$refs._form.resetFields();
            this.visible = false;
        }
    }
}
</script>

<style>
</style>