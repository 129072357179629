<template>
    <div>
        <jy-query ref="form" :model="form" labelWidth="130" class="query_area" size="small">
            <jy-query-item label="企业名称" prop="cName">
                <el-input v-model="form.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="卡号">
                <el-input placeholder="请输入" v-model="form.cardNo"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="监管机构">
                <div @click="selectMonInstitutions" prop="mOrgId">
                    <el-input placeholder="请选择" v-model="form.mOrgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="统一社会信用代码" prop="creditCode">
                <el-input v-model="form.creditCode"></el-input>
            </jy-query-item>
            <jy-query-item label="所属行政地区" prop="regionId">
                <div @click="selectRegion">
                    <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="性质" prop="nature">
                <el-select v-model="form.nature">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="工业" value="0"></el-option>
                    <el-option label="非工业" value="1"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="状态" prop="status">
                <el-select v-model="form.status">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="禁用" value="0"></el-option>
                    <el-option label="启用" value="1"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属行业" prop="industry">
                <el-select v-model="form.industry">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="t in industryList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="企业类型" prop="wUnitType">
                <el-select v-model="form.wUnitType">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="t in orgTypeList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="发卡状态" prop="cardNoStatus">
                <el-select v-model="form.cardNoStatus">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已发卡" value="1"></el-option>
                    <el-option label="未发卡" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="合同日期" prop="contractT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.contractT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesBasicInfoRegisterList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesBasicInfoRegisterReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesBasicInfoRegisterInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesBasicInfoRegisterExport') && false"
                        url="/unitInfo/export"
                        fileName="企业单位信息"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                    <el-button size="small" type="primary" @click="showList">自定义列表</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('cName')" label="企业名称" prop="cName" min-width="150"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('cardNo')" label="卡号" prop="cardNo" min-width="150"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('orgNa')" label="所属机构" prop="orgNa" min-width="150"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('mOrgNa')" label="监管机构" prop="mOrgNa" min-width="150"></jy-table-column>
                <jy-table-column v-if="columnProps.includes('addr')" label="单位注册地址" prop="addr" width="150"></jy-table-column>
                <jy-table-column label="产废设施地址" v-if="columnProps.includes('rAddr')" prop="rAddr" width="150">
                    <template v-slot="scope">{{ formatAddrList(scope.row.addrList) }}</template>
                </jy-table-column>
                <jy-table-column label="所属行政地区" prop="regionNa" v-if="columnProps.includes('regionNa')" width="150"></jy-table-column>
                <jy-table-column label="合同开始日期" prop="contractStartT" v-if="columnProps.includes('contractStartT')" width="150"></jy-table-column>
                <jy-table-column label="合同结束日期" prop="contractEndT" v-if="columnProps.includes('contractEndT')" width="150"></jy-table-column>
                <jy-table-column label="状态" prop="status" v-if="columnProps.includes('status')">
                    <template v-slot="scope">{{ scope.row.status === "1" ? "启用" : "禁用" }}</template>
                </jy-table-column>
                <jy-table-column label="所属行业" prop="industry" v-if="columnProps.includes('industry')"></jy-table-column>
                <jy-table-column label="联系人" prop="contact" v-if="columnProps.includes('contact')"></jy-table-column>
                <jy-table-column label="联系人职位" prop="conPos" v-if="columnProps.includes('conPos')" width="100"></jy-table-column>
                <jy-table-column label="联系电话" prop="conPhone" v-if="columnProps.includes('conPhone')" min-width="130"></jy-table-column>
                <jy-table-column label="企业类型" prop="wUnitType" v-if="columnProps.includes('wUnitType')"></jy-table-column>
                <jy-table-column label="统一社会信用代码" prop="creditCode" v-if="columnProps.includes('creditCode')" width="160"></jy-table-column>
                <jy-table-column label="建设规模" prop="oScale" v-if="columnProps.includes('oScale')" min-width="120"></jy-table-column>
                <jy-table-column label="性质" prop="nature" v-if="columnProps.includes('nature')" min-width="120">
                    <template v-slot="scope">{{ scope.row.nature === "1" ? "非工业" : "工业" }}</template>
                </jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa" v-if="columnProps.includes('cUserNa')" min-width="120"></jy-table-column>
                <jy-table-column label="备注" prop="remark" v-if="columnProps.includes('remark')" min-width="150"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <region-tree ref="region" @select="addRegion"></region-tree>
        <institutions-tree refresh :showType="showType" ref="institutions" @addInstitutions="addInstitutions" :title="orgTitle"></institutions-tree>
        <t-fixed ref="tFixed" tagTitle="label" :banList="['cName']"></t-fixed>
        <add-card ref="addCard" @update="getList" />
        <Charge ref="charge" @update="getList" />
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import regionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
    import { btnMixins } from "@/common/js/button.mixin";
    import TFixed from "@/components/pages/admin/common/TFixed/tFixed.vue";
    import AddCard from '@/components/pages/admin/basic/rubbishUnit/AddCard.vue';
    import Charge from '@/components/pages/admin/basic/rubbishUnit/Charge.vue';

    export default {
        created() {
            this.setOperateList();
        },
        mounted() {
            if (this.$route.params && this.$route.params.cName) {
                this.form.cName = this.$route.params.cName;
            }
            this.getList();

            const columns = this.$refs.tFixed.showList;
            this.columnProps = columns.length ? columns : this.columns.map(v => v.prop);
        },
        activated() {
            if (this.$route.params && this.$route.params.cName) {
                this.form.cName = this.$route.params.cName;
                this.getList();
            }
        },
        mixins: [btnMixins],
        methods: {
            showList() {
                this.$refs.tFixed.init(this.columns);
            },
            selectRegion() {
                this.$refs.region.init();
            },
            addRegion(data) {
                this.form.regionId = data.regionId;
                this.form.regionNa = data.regionNa;
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            async getList() {
                this.loading = true;
                let url = "/unitInfo/pageQuery";
                const { detail: listDetail } = await this.$http.post(
                    url,
                    {
                        unitInfo: {
                            ...this.form,
                            unitType: "0",
                            contractStartT: this.form.contractT[0],
                            contractEndT: this.form.contractT[1]
                        },
                        orgIds: [],
                        mOrgIds: [],
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        cardNoStatus:this.form.cardNoStatus
                    },
                    { isRequestParam: false }
                );
                this.dataList = listDetail.list.map(v => {
                    return {
                        ...v.unitInfo,
                        orgId: v.sysOrg && v.sysOrg.orgId,
                        orgNa: v.sysOrg && v.sysOrg.orgNa,
                        mOrgId: v.mSysOrg && v.mSysOrg.orgId,
                        mOrgNa: v.mSysOrg && v.mSysOrg.orgNa
                    };
                });
                this.total = listDetail.total;

                const { detail: addrList } = await this.$http.post("/unitInfo/batchQueryByUnitIds", this.dataList.map(v => v.unitId).join(","));

                this.dataList.forEach(row => {
                    const l = addrList.filter(v => v.unitId === row.unitId);
                    this.$set(row, "addrList", l);
                });
                this.loading = false;
            },
            add() {
                this.$emit("switch", { target: 'Detail' });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.form.mOrgId = "";
                this.form.mOrgNa = "";
                this.form.regionNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            handleEdit(row) {
                this.$emit("switch", {
                    target: 'Detail',
                    unitId: row.unitId,
                    isEdit: true
                });
            },
            handleDelete(row) {
                this.$confirm("是否删除该企业单位?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/unitInfo/delete";
                        this.$http.post(url, row.unitId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            if (this.dataList.length === 1 && this.pageIndex > 1) {
                                this.pageIndex--;
                            }
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            addInstitutions(data, flag) {
                if (flag === "mOrg") {
                    this.form.mOrgId = data.orgId;
                    this.form.mOrgNa = data.orgNa;
                } else {
                    this.form.orgId = data.orgId;
                    this.form.orgNa = data.orgNa;
                }
            },
            // 选择机构树
            selectInstitutions() {
                this.showType = [1];
                this.orgTitle = "选择所属机构";
                this.$refs.institutions.init({ flag: "org" });
            },
            selectMonInstitutions() {
                this.showType = [2];
                this.orgTitle = "选择监管机构";
                this.$refs.institutions.init({ flag: "mOrg" });
            },
            handleView(row) {
                this.$emit("switch", {
                    target: 'Detail',
                    unitId: row.unitId,
                    isView: true
                });
            },
            handleAddCard(row) {
                this.$refs.addCard.init({
                    title: '发卡',
                    unitId: row.unitId
                });
            },
            handleCharge(row) {
                console.log(row)
                this.$refs.charge.init({
                    title: '充值',
                    unitId: row.unitId,
                    unitNa: row.cName,
                    orgNa: row.orgNa
                });
            },
            handleAccountDetail(row) {
                this.$emit("switch", {
                    unitId: row.unitId,
                    unitNa: row.cName,
                    addr: row.addr,
                    title: '产废企业登记 > 账户明细',
                    target: 'AccountDetail'
                });
            },
            setOperateList() {
                let l = [
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoRegisterView");
                        }
                    },
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleEdit,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoRegisterUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoRegisterDelete");
                        }
                    },
                    {
                        name: "发卡",
                        icon: "el-icon-delete",
                        fun: this.handleAddCard,
                        isShow: (row) => {
                            return !row.cardNo && this.btnexist("wastesBasicInfoRegisterSendCard");
                        }
                    },
                    {
                        name: "充值",
                        icon: "el-icon-delete",
                        fun: this.handleCharge,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoRegisterCharge");
                        }
                    },
                    {
                        name: "账户明细",
                        icon: "el-icon-delete",
                        fun: this.handleAccountDetail,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoRegisterAccountDetail");
                        }
                    }
                ];
                this.operateList = l;
            },
            formatAddrList(list) {
                if (list && list.length) {
                    return list.map(v => v.addr).join(",");
                } else {
                    return "-";
                }
            }
        },
        computed: {
            orgTypeList() {
                return this.$parent.orgTypeList;
            },
            industryList() {
                return this.$parent.industryList;
            }
        },
        data() {
            return {
                loading: false,
                orgTitle: "选择所属机构",
                dataList: [],
                total: 0,
                form: {
                    cName: "",
                    orgId: "",
                    orgNa: "",
                    mOrgId: "",
                    mOrgNa: "",
                    creditCode: "",
                    regionId: undefined,
                    regionNa: "",
                    nature: "",
                    status: "",
                    industry: "",
                    wUnitType: "",
                    cardNo: "",
                    cardNoStatus:'',
                    contractT: []
                },

                pageIndex: 1,
                pageSize: 10,
                operateList: [],
                btnMenuId: "wastesBasicInfoRegister",

                showType: [0, 1, 2],

                columnProps: [],
                columns: [
                    { label: "企业名称", prop: "cName" },
                    { label: "所属机构", prop: "orgNa" },
                    { label: '卡号', prop: 'cardNo' },
                    { label: "监管机构", prop: "mOrgNa" },
                    { label: "单位注册地址", prop: "addr" },
                    { label: "所属行政地区", prop: "regionNa" },
                    { label: "合同开始日期", prop: "contractStartT" },
                    { label: "合同结束日期", prop: "contractEndT" },
                    { label: "状态", prop: "status" },
                    { label: "所属行业", prop: "industry" },
                    { label: "联系人", prop: "contact" },
                    { label: "联系人职位", prop: "conPos" },
                    { label: "联系电话", prop: "conPhone" },
                    { label: "企业类型", prop: "wUnitType" },
                    { label: "统一社会信用代码", prop: "creditCode" },
                    { label: "建设规模", prop: "oScale" },
                    { label: "性质", prop: "nature" },
                    { label: "制单人", prop: "cUserNa" },
                    { label: "备注", prop: "remark" }
                ]
            };
        },
        components: {
            ExportExcel,
            institutionsTree,
            regionTree,
            TFixed,
            AddCard,
            Charge
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
