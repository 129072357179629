<template>
    <div>
        <div>
            <el-page-header @back="backMain" :content="'产废企业登记 > ' + title"></el-page-header>
            <el-form :model="model" ref="rUnitForm" class="detail_form" size="small" label-position="right" label-width="130px" :rules="rules">
                <div class="form_item">
                    <div class="head_title">企业信息登记</div>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="企业名称" prop="cName">
                                <el-input v-model="model.cName" v-if="!isView"></el-input>
                                <span v-else>{{ model.cName }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="所属机构" prop="orgId">
                                <div @click="selectInstitutions" v-if="!isView">
                                    <el-input placeholder="请选择" v-model="model.orgNa"></el-input>
                                </div>
                                <span v-else>{{ model.orgNa }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="监管机构" prop="mOrgId">
                                <div @click="selectMorgInstitutions" v-if="!isView">
                                    <el-input placeholder="请选择" v-model="model.mOrgNa"></el-input>
                                </div>
                                <span v-else>{{ model.mOrgNa }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="产废设施地址" prop="latLngList">
                                <div @click="selectAddress" v-if="!isView">
                                    <el-input placeholder="请选择" :value="lnglatsStr"></el-input>
                                </div>
                                <span v-else>{{ lnglatsStr }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="所属分拣中心" prop="pUnitId">
                                <el-select v-if="!isView" v-model="model.pUnitId">
                                    <el-option v-for="t in pUnitList" :key="t.unitId" :label="t.cname" :value="t.unitId"></el-option>
                                </el-select>
                                <span v-else>{{ formatPUnitT(model.pUnitId) }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="单位注册地址" prop="addr">
                                <el-input v-model="model.addr" v-if="!isView"></el-input>
                                <span v-else>{{ model.addr }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="所属行政地区" prop="regionId">
                                <div @click="selectRegion" v-if="!isView">
                                    <el-input placeholder="请选择" v-model="model.regionId"></el-input>
                                </div>
                                <span v-else>{{ model.regionId }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="联系人" prop="contact">
                                <el-input v-model="model.contact" v-if="!isView"></el-input>
                                <span v-else>{{ model.contact }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="联系人职位" prop="conPos">
                                <el-input v-model="model.conPos" v-if="!isView"></el-input>
                                <span v-else>{{ model.conPos }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="联系人电话" prop="conPhone">
                                <el-input v-model="model.conPhone" v-if="!isView"></el-input>
                                <span v-else>{{ model.conPhone }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="合同开始日期" prop="contractStartT">
                                <el-date-picker v-if="!isView" v-model="model.contractStartT" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                                <span v-else>{{ model.contractStartT }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="合同结束日期" prop="contractEndT">
                                <el-date-picker v-if="!isView" v-model="model.contractEndT" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                                <span v-else>{{ model.contractEndT }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="状态" prop="status">
                                <el-select v-if="!isView" v-model="model.status">
                                    <el-option label="禁用" value="0"></el-option>
                                    <el-option label="启用" value="1"></el-option>
                                </el-select>
                                <span v-else>{{ model.status == "1" ? "启用" : "禁用" }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="所属行业" prop="industry">
                                <el-select v-if="!isView" v-model="model.industry">
                                    <el-option v-for="t in industryList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                                </el-select>
                                <span v-else>{{ model.industry }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="企业类型" prop="wUnitType">
                                <el-select v-if="!isView" v-model="model.wUnitType">
                                    <el-option v-for="t in orgTypeList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                                </el-select>
                                <span v-else>{{ model.wUnitType }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="统一社会信用代码">
                                <el-input v-if="!isView" v-model="model.creditCode"></el-input>
                                <span v-else>{{ model.creditCode }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="建设规模">
                                <el-input v-if="!isView" v-model="model.oScale"></el-input>
                                <span v-else>{{ model.oScale }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="性质">
                                <el-select v-if="!isView" v-model="model.nature">
                                    <el-option label="工业" value="0"></el-option>
                                    <el-option label="非工业" value="1"></el-option>
                                </el-select>
                                <span v-else>{{ model.nature == "1" ? "非工业" : "工业" }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="法定代表人">
                                <el-input v-if="!isView" v-model="model.legalPerson"></el-input>
                                <span v-else>{{ model.legalPerson }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="传真号码">
                                <el-input v-if="!isView" v-model="model.fax"></el-input>
                                <span v-else>{{ model.fax }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="邮箱">
                                <el-input v-if="!isView" v-model="model.email"></el-input>
                                <span v-else>{{ model.email }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="邮编">
                                <el-input v-if="!isView" v-model="model.postCode"></el-input>
                                <span v-else>{{ model.postCode }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="产废类别">
                                <el-select v-if="!isView" v-model="model.wasteT">
                                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                                </el-select>
                                <span v-else>{{ formatWasteT(model.wasteT) }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="产废废物名称">
                                <el-input v-if="!isView" v-model="model.wasteNa"></el-input>
                                <span v-else>{{ model.wasteNa }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="备注">
                                <el-input v-if="!isView" v-model="model.remark"></el-input>
                                <span v-else>{{ model.remark }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="卡号">
                                <el-input v-if="!isView" v-model="model.cardNo"></el-input>
                                <span v-else>{{ model.cardNo }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="其他">
                                <el-upload
                                    v-if="!isView"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleOtherAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <img v-if="model.otherUrl" :src="model.otherUrl" class="avatar" />
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <el-image class="avatar" fit="contain" :src="model.otherUrl" v-else>
                                    <div slot="error">
                                        <div class="image_slot">
                                            <span v-if="model.otherUrl">加载失败</span>
                                            <span v-else>未上传</span>
                                        </div>
                                    </div>
                                </el-image>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="营业执照照片">
                                <el-upload
                                    v-if="!isView"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleLicenceUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <img v-if="model.licenceUrl" :src="model.licenceUrl" class="avatar" />
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <el-image class="avatar" fit="contain" :src="model.licenceUrl" v-else>
                                    <div slot="error">
                                        <div class="image_slot">
                                            <span v-if="model.licenceUrl">加载失败</span>
                                            <span v-else>未上传</span>
                                        </div>
                                    </div>
                                </el-image>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="签约合同照片">
                                <el-upload
                                    v-if="!isView"
                                    class="avatar-uploader"
                                    :action="uploadImgUrl"
                                    :show-file-list="false"
                                    :on-success="handleContractUrlAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :headers="uploadHeader"
                                >
                                    <img v-if="model.contractUrl" :src="model.contractUrl" class="avatar" />
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <el-image class="avatar" fit="contain" :src="model.contractUrl" v-else>
                                    <div slot="error">
                                        <div class="image_slot">
                                            <span v-if="model.contractUrl">加载失败</span>
                                            <span v-else>未上传</span>
                                        </div>
                                    </div>
                                </el-image>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div class="head_title">账号信息</div>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="登陆账号" prop="userNa">
                                <el-input v-model="model.userNa" v-if="!isView"></el-input>
                                <span v-else>{{ model.userNa }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="用户姓名" prop="markNa">
                                <el-input v-model="model.markNa" v-if="!isView"></el-input>
                                <span v-else>{{ model.markNa }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="登陆密码" prop="passwd">
                                <el-input v-model="model.passwd" v-if="!isView"></el-input>
                                <span v-else>{{ model.passwd }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="!isView">
                        <el-col :span="8">
                            <el-form-item label="确认密码" prop="confirmPasswd">
                                <el-input v-model="model.confirmPasswd" v-if="!isView"></el-input>
                                <span v-else>{{ model.confirmPasswd }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="form_item" v-if="isView">
                    <div class="head_title">环服上门</div>
                    <env-record :unitId="model.unitId"></env-record>
                </div>
                <div class="form_item" v-if="isView">
                    <div class="head_title">企业自送</div>
                    <org-record :unitId="model.unitId"></org-record>
                </div>
            </el-form>
            <div class="form_handle" v-if="!isView">
                <el-button type="primary" size="small" @click="saveUnit">确定</el-button>
                <el-button size="small" @click="backMain">取消</el-button>
            </div>
        </div>
        <select-addr ref="addr" :limit="5" @select="getAddr"></select-addr>
        <region-tree ref="region" @select="addRegion"></region-tree>
        <institutions-tree refresh :showType="showType" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>

<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import regionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
    import SelectAddr from "@/components/pages/admin/map/SelectAddr.vue";
    import OrgRecord from "@/components/pages/admin/basic/rubbishUnit/OrgRecord.vue";
    import EnvRecord from "@/components/pages/admin/basic/rubbishUnit/EnvRecord.vue";
    export default {
        methods: {
            formatPUnitT(val) {
                const w = this.pUnitList.find(v => v.unitId === val);
                return w ? w.cname : "-";
            },
            formatWasteT(val) {
                const w = this.wasteTypes.find(v => v.wasteTId === val);
                return w ? w.cName : "-";
            },
            selectRegion() {
                this.$refs.region.init();
            },
            addRegion(data) {
                this.model.regionId = data.regionId;
                this.model.regionNa = data.regionNa;
            },
            backMain() {
                this.$emit("switch", { target: 'List' });
            },
            getAddr(list) {
                this.model.latLngList = list.map(v => {
                    return {
                        lng: v.lng,
                        lat: v.lat,
                        addr: v.addr,
                        unitId: this.model.unitId
                    };
                });
            },
            selectAddress() {
                this.$refs.addr.init(this.model.latLngList);
                // console.log(this.model.latLngList);
            },
            addInstitutions(data, flag) {
                if (flag === "mOrg") {
                    this.model.mOrgId = data.orgId;
                    this.model.mOrgNa = data.orgNa;
                } else {
                    this.model.orgId = data.orgId;
                    this.model.orgNa = data.orgNa;
                }
            },
            // 选择机构树
            selectInstitutions() {
                this.showType = [1];
                this.$refs.institutions.init({ flag: "org" });
            },
            // 选择监管机构树
            selectMorgInstitutions() {
                this.showType = [2];
                this.$refs.institutions.init({ flag: "mOrg" });
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            handleOtherAvatarSuccess({ detail }) {
                this.model.otherUrl = detail;
            },
            handleLicenceUrlAvatarSuccess({ detail }) {
                this.model.licenceUrl = detail;
            },
            handleContractUrlAvatarSuccess({ detail }) {
                this.model.contractUrl = detail;
            },
            saveUnit() {
                let url = "/unitInfo/add";
                if (this.isEdit) {
                    url = "/unitInfo/update";
                }
                console.log(this.model);
                this.$refs.rUnitForm.validate(valide => {
                    if (valide) {
                        this.$http
                            .post(url, {
                                ...this.model,
                                unitType: "0"
                            })
                            .then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                this.$emit("switch", { target: "List" });
                            });
                    }
                });
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            init() {
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                });

                this.$http.post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "3"
                })
                .then(res => {
                    this.pUnitList = res.detail;
                });
            }
        },
        created() {
            this.setUploadHeader();
            this.init();
        },
        mounted() {
            const options = this.$parent.options;
            if (options) {
                this.isEdit = options.isEdit;
                this.isView = options.isView;
                this.model.unitId = options.unitId;
                this.$http.post("/unitInfo/queryByUnitId", this.model.unitId).then(res => {
                    this.model = {
                        ...res.detail.unitInfo,
                        orgId: res.detail.sysOrg.orgId,
                        orgNa: res.detail.sysOrg.orgNa,
                        mOrgId: res.detail.mSysOrg.orgId,
                        mOrgNa: res.detail.mSysOrg.orgNa,
                        latLngList: this.model.latLngList ? this.model.latLngList : []
                    };
                });
                this.$http.post("/unitInfo/batchQueryByUnitIds", this.model.unitId).then(res => {
                    this.model.latLngList = res.detail;
                });
                this.title = "编辑";
                if (this.isView) {
                    this.title = "查看";
                }
            } else {
                this.isEdit = false;
                this.title = "新增";
            }
        },
        data() {
            return {
                title: "",
                model: {
                    pUnitId: "",
                    unitId: "",
                    cName: "",
                    orgNa: "",
                    orgId: "",
                    mOrgNa: "",
                    mOrgId: "",
                    addr: "",
                    regionId: "",
                    regionNa: "",
                    contact: "",
                    conPos: "",
                    conPhone: "",
                    contractStartT: "",
                    contractEndT: "",
                    status: "",
                    industry: "",
                    wUnitType: "",
                    creditCode: "",
                    oScale: "",
                    nature: "",
                    legalPerson: "",
                    fax: "",
                    email: "",
                    postCode: "",
                    wasteT: "",
                    wasteNa: "",
                    cardNo: "",
                    remark: "",
                    contractUrl: "",
                    licenceUrl: "",
                    otherUrl: "",
                    latLngList: [],
                    userNa: "",
                    markNa: "",
                    passwd: "",
                    confirmPasswd: "",
                },
                isEdit: false,
                isView: false,

                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {},
                rules: {
                    pUnitId: [{ required: true, message: '所属分拣中心不为空', treigger: 'change' }],
                    cName: [{ required: true, message: "企业名称不为空", trigger: "blur" }],
                    orgId: [{ required: true, message: "所属机构不为空", trigger: "change" }],
                    mOrgId: [{ required: true, message: "监管机构不为空", trigger: "change" }],
                    addr: [{ required: true, message: "单位注册地址不为空", trigger: "blur" }],
                    regionId: [{ required: true, message: "所属行政地区不为空", trigger: "change" }],
                    contact: [{ required: true, message: "联系人不为空", trigger: "blur" }],
                    conPos: [{ required: true, message: "联系人职位不为空", trigger: "blur" }],
                    conPhone: [{ required: true, message: "联系人电话不为空", trigger: "blur" }],
                    contractStartT: [{ required: true, message: "合同开始日期不为空", trigger: "change" }],
                    contractEndT: [{ required: true, message: "合同结束日期不为空", trigger: "change" }],
                    status: [{ required: true, message: "状态不为空", trigger: "change" }],
                    industry: [{ required: true, message: "所属行业不为空", trigger: "change" }],
                    latLngList: [{ required: true, message: "产废设施地址不为空", trigger: "change" }],
                    userNa: [{ required: true, message: "账号不为空", trigger: "change" }],
                    markNa: [{ required: true, message: "用户名不为空", trigger: "change" }],
                    passwd: [{ required: true, message: "密码不为空", trigger: "change" }],
                    confirmPasswd: [{ required: true, trigger: "change", validator: (rule, value, callback) => {
                        if (!value) {
                            callback(new Error('密码不为空'))
                        } else if (value !== this.model.passwd) {
                            callback(new Error('与密码不相同'));
                        } else {
                            callback();
                        }
                    } }],
                },

                wasteTypes: [],
                pUnitList: [],

                list1: [],
                list2: [],
                showType: [0, 1, 2]
            };
        },
        components: {
            institutionsTree,
            regionTree,
            SelectAddr,
            OrgRecord,
            EnvRecord
        },
        computed: {
            orgTypeList() {
                return this.$parent.orgTypeList;
            },
            industryList() {
                return this.$parent.industryList;
            },
            lnglatsStr() {
                if (this.model.latLngList) {
                    return this.model.latLngList.map(v => v.addr).join(";");
                } else {
                    return "";
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .avatar {
        height: 100px;
        width: 100px;
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }

    .form_handle {
        text-align: center;
    }
    .image_slot{
        height: 100px;
        width: 100px;
        text-align: center;
        line-height: 100px;
        background: #ececec;
    }
</style>
